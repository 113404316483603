export default class Switcher {
    private $cont: JQuery;
    private $el: JQuery;
    private rev: boolean;

    public constructor($el: JQuery, $cont: JQuery, rev: boolean = false) {
        this.$el = $el;
        this.$cont = $cont;
        this.rev = rev;
    }

    public updateBool(forced: boolean = false): void {
        let val = this.$el.is(':checked');
        let show = this.rev ? !val : val;

        this.toggle(show, forced);
    }

    public updateVal(val: any, forced: boolean = false): void {
        let ival = this.$el.filter(':checked').val();
        let show = this.rev ? val != ival : val == ival;

        this.toggle(show, forced);
    }

    private toggle(show: boolean, forced: boolean): void {
        if (show) {
            if (forced) {
                this.$cont.show();
            } else {
                this.$cont.slideDown();
            }
        } else {
            if (forced) {
                this.$cont.hide();
            } else {
                this.$cont.slideUp();
            }
        }
    }

    public static init($el: JQuery, $cont: JQuery, rev: boolean = false): void {
        let s = new Switcher($el, $cont, rev);

        $el.change(() => {
            s.updateBool();
        });

        s.updateBool(true);
    }

    public static initVal($el: JQuery, value: any, $cont: JQuery, rev: boolean = false): void {
        let s = new Switcher($el, $cont, rev);

        $el.change(() => {
            s.updateVal(value);
        });

        s.updateVal(value, true);
    }
}
