import './utils/ajax';
import './utils/spinner';
import Cart from './cart/Cart';
import Panel from './Panel';
import Form from '../../modules/core/forms/theme/js/Form';
import Utils from '../../modules/utils/utils-js/theme/js/utils/Utils';
import SiteProduct from './SiteProduct';
import OpenInfo from '../../modules/utils/utils-js/theme/js/utils/OpenInfo';
import ObjectFit from '../../modules/utils/utils-js/theme/js/utils/ObjectFit';
import Switcher from './utils/Switcher';

declare let Slider;
declare let FB;

class Site {
    public static Cart:Cart = Cart;
    public static Panel:Panel = Panel;

    public static onRegisterForm($uid: JQuery): void {
        new Form($uid);

        Switcher.initVal($('input[name="iscompany"]', $uid), 1, $('._js-forcompany', $uid));
        Switcher.initVal($('input[name="iscompany"]', $uid), 1, $('._js-forperson', $uid), true);

        Switcher.initVal($('input[name="sameaddress"]', $uid), 1, $('._js-forsameaddress', $uid), true);

        Switcher.initVal($('input[name="send_iscompany"]', $uid), 1, $('._js-forcompany2', $uid));
        Switcher.initVal($('input[name="send_iscompany"]', $uid), 1, $('._js-forperson2', $uid), true);

        (<any> Site).discover($uid);
    }

    public static onPageHome(): void {
        new Slider($('.mainslider'), {
        	fixed: true,
        	max: 1
        });
    }

    public static onPageProducts(): void {
        $(() => {
            const $sortby = $('.filterbox__sortby select');

            $sortby.each((ix, el) => {
                const $el = $(el);
                const $others = $sortby.not($el);

                $el.on('change', () => {
                    $others.val($el.val());
                });
            });

            let $inputs = $();
            $inputs = $inputs.add($sortby);
            $inputs = $inputs.add('.addoncat-selector select');
            $inputs = $inputs.add('.addoncat-selector input');

            $inputs.on('change', (e) => {
                let vals = {};

                const $filter = $('.filterbox--head');
                const cat = $filter.data('category');

                if (cat) {
                    vals['category'] = cat;
                }

                const $search = $('.searchform--results .input__input--text');
                const search = $search.val();

                if (search) {
                    vals['query'] = search;
                }

                $inputs.each((ix, el) => {
                    const $el = $(el);

                    const name = $el.prop('name');

                    if ($el.is('select')) {
                        const val = $el.val();

                        if (val) {
                            vals[name] = val;
                        }
                    } else if ($el.is('[type="checkbox"]')) {
                        if ($el.is(':checked')) {
                            vals[name] = 1;
                        }
                    }
                });

                $.get('/products-sort', vals, (r) => {
                    if (r.success) {
                        window.location = r.url;
                    } else {
                        throw "Response error";
                    }
                });
            });
        });
    }

    public static discover($parent?: JQuery): void {
        Utils.updateElements($parent);
        Cart.discover($parent);

        Form.initRadios($('.vproduct .radio-items', $parent));
    }

    private static updateFloat() {
        const top = $(window).scrollTop();
        const wh = $(window).height();
        const wx = $(window).width();

        const $body = $('body');
        const $header = $('.header');
        const $mainmenu = $('.mainmenu');
        const $menu = $('.menu--main');

        const head = 200;
        const mx = 992;

        $body.toggleClass('body--float', top > 0);
        $body.toggleClass('body--no-float', top == 0);

        $body.toggleClass('body--float-head', top > head || wx <= mx);
        $body.toggleClass('body--no-float-head', top <= head && wx > mx);

        $mainmenu.toggleClass('mainmenu--mobile', top > head || wx <= mx);
        $mainmenu.toggleClass('mainmenu--full', top <= head && wx > mx);

        $menu.toggleClass('menu--mobile', top > head || wx <= mx);
        $menu.toggleClass('menu--full', top <= head && wx > mx);

        $header.toggleClass('header--mobile', top > head || wx <= mx);
        $header.toggleClass('header--full', top <= head && wx > mx);
    }

    private static windowOpen(url, name): void {
        var width = 575;
        var height = 300;

        var left = ($(window).width()  - width)  / 2;
        var top = ($(window).height() - height) / 2;

        var opts = 'status=1,width=' + width + ',height=' + height + ',top=' + top + ',left=' + left;

        return (<any> window).open(url, name, opts);
    }

    private static initShares($parent?: JQuery): void {
        $('.js-share--facebook', $parent).click((e) => {
            e.preventDefault();

            FB.ui({
                method: 'share',
                href: window.location.href
            });
        });

        $('.js-share--twitter', $parent).click((e) => {
            e.preventDefault();

            this.windowOpen('https://twitter.com/share', 'twitter');
        });
    }

    private static initBestsellers(): void {
        let $bestsellerslider = $('.bestsellerslider');

        if ($bestsellerslider.length) {
            let $tabs = $('.bestsellerslider__tabs-item', $bestsellerslider);
            let $pages = $('.bestsellerslider__tab', $bestsellerslider);

            const selectfunc = (ix: number): void => {
                let $page = $pages.eq(ix);
                let $tab = $tabs.eq(ix);
                let $slider = $('.productslider2__slider', $page);
                let slider: any = $slider.data('slider');

                $pages.hide();
                $page.show();

                $tabs.removeClass('bestsellerslider__tabs-item--selected');
                $('.button', $tabs).removeClass('button--selected');

                $tab.addClass('bestsellerslider__tabs-item--selected');
                $('.button', $tab).addClass('button--selected');

                slider.updateHtml();
            };

            $tabs.each((ix, el) => {
                let $tab = $(el);

                $tab.click((e) => {
                    selectfunc($tab.index());
                });
            });

            selectfunc(0);
        }
    }

    private static initCategories() {
        let $catlist = $('.categorieslist__outer');
        let $toggle = $('.categorieslist__toggle', $catlist);
        let $items = $('.categorieslist__item', $catlist);

        $toggle.click(() => {
            $catlist.toggleClass('categorieslist--mobilehide');
        });

        $items.each((ix, el) => {
            let $el = $(el);

            let $head = $('> .categorieslist__head', $el);
            let $switch = $('.categorieslist__switch', $head);
            let $children = $('> .categorieslist', $el);

            $switch.click(() => {
                $el.toggleClass('categorieslist__item--opened');
                $children.slideToggle();
            });
        });

        let $focused = $('.categorieslist__item--selected', $items).parents('.categorieslist__item');
        if (!$focused.length) {
            $focused =$('.categorieslist__item--selected', '.categorieslist');
        }

        $focused.addClass('categorieslist__item--opened categorieslist__item--focused');
        $('> .categorieslist', $focused).show();
    }

    private static initHtml() {
        var $mainmenu=$('.mainmenu');

        $('.menubutton', $mainmenu).click(function(e) {
            e.preventDefault();

            $mainmenu.toggleClass('mainmenu--opened', !$mainmenu.hasClass('mainmenu--opened'))
            $('body').toggleClass('body--menuopened', $mainmenu.hasClass('mainmenu--opened'))
        });

        this.initCategories();
        this.initShares();

        this.discover();
    }

    private static initRwd() {
        var $header = $('.header');
        $header.addClass('header--noanim');

        $(window).resize(Site.updateFloat);
        $(window).scroll(Site.updateFloat);

        Site.updateFloat();

        $header.removeClass('header--noanim');
    }

    public static evalJs(): void {
        (<any> window)._js = (h: () => void) => {
            h();
        };

        let js = (<any> window)._jslist;

        $.each(js, (k, v) => {
            v();
        });
    }

    public static init() {
        (<any> window).Site = Site;

        if ((<any> window).gtag == null) {
            (<any> window).gtag =  function () {
                console.log(arguments);
            };
        }

        SiteProduct.initModule();
        OpenInfo.initModule();
        ObjectFit.initModule();
        Form.initModule();

        $(document).ready(() => {
            Site.initHtml();
            Site.initRwd();
            Site.initBestsellers();
        });

        $(window).on('load', () => {
            $('body').addClass('body--loaded');

            setTimeout(() => {
                $('body').removeClass('body--loading');
                $('body').addClass('body--loaded2');
                $('body').trigger('pageloaded');
            }, 500);
        });
    }
}

Site.init();
