import Switcher from "./utils/Switcher";
import Form from "../../modules/core/forms/theme/js/Form";

export default class Panel {
    public static onAddressForm($uid:JQuery): void {
        new Form($uid);

        (<any> window).Site.discover($uid);

        Switcher.init($('input[name="iscompany"]', $uid), $('._js-forcompany', $uid));
        Switcher.init($('input[name="iscompany"]', $uid), $('._js-forperson', $uid), true);

        Switcher.init($('input[name="send_iscompany"]', $uid), $('._js-forcompany2', $uid));
        Switcher.init($('input[name="send_iscompany"]', $uid), $('._js-forperson2', $uid), true);

        Switcher.init($('input[name="sameaddress"]', $uid), $('._js-forsameaddress', $uid), true);
    }
}
