import Switcher from "../utils/Switcher";
import AddToCart from "./AddToCart";
import Form from "../../../modules/core/forms/theme/js/Form";

export default class Cart {
	public static onSummaryForm($uid: JQuery): void {
		(<any> $uid).form();

		(<any> window).Site.discover($uid);
	}

	public static onUserForm($uid: JQuery): void {
		new Form($uid);

		Switcher.init($('input[name="iscompany"]', $uid), $('._js-forcompany', $uid));
		Switcher.init($('input[name="iscompany"]', $uid), $('._js-forperson', $uid), true);

		Switcher.init($('input[name="sameaddress"]', $uid), $('._js-forsameaddress', $uid), true);
		Switcher.init($('input[name="send_iscompany"]', $uid), $('._js-forcompany2', $uid));
		Switcher.init($('input[name="send_iscompany"]', $uid), $('._js-forperson2', $uid), true);

		Switcher.init($('input[name="account"]', $uid), $('._js-foraccount', $uid));

		(<any> window).Site.discover($uid);
	}

	public static onUserVerifyForm($uid: JQuery): void {
		new Form($uid);

		Switcher.init($('input[name="iscompany"]', $uid), $('._js-forcompany', $uid));
		Switcher.init($('input[name="iscompany"]', $uid), $('._js-forperson', $uid), true);

		Switcher.init($('input[name="sameaddress"]', $uid), $('._js-forsameaddress', $uid), true);
		Switcher.init($('input[name="send_iscompany"]', $uid), $('._js-forcompany2', $uid));
		Switcher.init($('input[name="send_iscompany"]', $uid), $('._js-forperson2', $uid), true);

		(<any> window).Site.discover($uid);
	}

	public static onService($uid: JQuery): void {
		$uid.find(':input').change(function(e) {
			$('body').trigger('cartUpdateInit');

			var data={
				group: $(this).data('group'),
				id: $(this).val()
			};

			$.post('/cartapi/services', data, function() {
				$('body').trigger('cartUpdate');
			});
		});
	}

	public static onServicesForm($uid: JQuery): void {
		(<any> window).Site.discover($uid);
	}

	public static onUser(): void {
		let $nav = $('.vcartuser__nav-item');
		let $tabs = $('.vcartuser__tab');

		function show(ix) {
			$nav.removeClass('vcartuser__nav-item--selected');
			$nav.eq(ix).addClass('vcartuser__nav-item--selected');

			$tabs.hide();
			$tabs.eq(ix).show();
		}

		$nav.click(function (e) {
			e.preventDefault();

			let $this = $(this);
			let ix = $this.index();

			show(ix);

			localStorage.setItem('cart.user.tab', ix.toString());
		});

		let ix: number;

		if (localStorage.getItem('cart.user.tab') != null) {
			ix = parseInt(localStorage.getItem('cart.user.tab'));
		} else {
			ix = 0;
		}

		show(ix);
	}

	public static onCartFormProducts($uid: JQuery): void {
		let $this = $uid;

		function update(id, quantity) {
			$('body').trigger('cartUpdateInit');

			$.post('/cartapi/set', {
				id: id,
				quantity: quantity
			}, function() {
				$('body').trigger('cartUpdate');
			});
		}

		$uid.find('.quantity input').each(function() {
			var $this=$(this);

			(<any> $this).qSpinner({
				value: $this.val(),

				onLazySpin: function(e, val) {
					update($this.closest('tr').data('id'), val);
				}
			});
		});

		$uid.find('.remove').click(function(e) {
			e.preventDefault();

			update($(this).closest('tr').data('id'), 0);
		});

		$uid.find(':input').change(function(e) {
			update($(this).closest('tr').data('id'), $(this).val());
		});

		(<any> window).Site.discover($uid);
	}

	public static onCartForm($uid: JQuery): void {
		$('body').one('cartUpdateInit', function() {
			$uid.find(':input').prop('disable', 'disable');

			(<any> $uid).ajaxLoader();
		});

		$('body').one('cartUpdate', function() {
			(<any> $uid).ajaxUpdate($uid.data('action'));
		});

		(<any> window).Site.discover($uid);
	}

	public static discover($parent?: JQuery): void {
		$('.addtocart', $parent).each(function() {
			new AddToCart($(this));
		});
	}

	public static init(): void {
		$(() => {
			$('body').on('cartUpdateInit', function() {
				// $('#cartsummary').ajaxLoader();
			});

			var timer=null;

			$('body').on('cartUpdate', function() {
				if (timer!==null) {
					window.clearTimeout(timer);
					timer=null;
				}

				$.get('/cartapi/info', function(info) {
					let $headercart = $('.header__cart');

					$headercart.addClass('flash');
					$('.header__cart-total', $headercart).text(info.total);

					setTimeout(() => {
						$headercart.removeClass('flash');
					}, 2000);
				});
			});
		});
	}
}

Cart.init();
