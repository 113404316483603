declare let gtag;

export default class AddToCart {
    private $el;

    public constructor($el: JQuery) {
        this.$el = $el;

        this.initHtml();
    }

    public initHtml(): void {
        var $this = this.$el;

        var $quantity=$('.addtocart__quantity', $this);
        var $button=$('.addtocart__button', $this);

        var adding=false;

        function reset() {
            $quantity.val(1);
        }

        function getVars() {
            var vars=$(':input', $this).serializeArray();

            if ($this.data('id')) {
                vars.push({
                    name: 'id',
                    value: $this.data('id')
                });
            }

            return vars;
        }

        function submit() {
            if (adding) {
                return;
            }

            adding=true;

            $this.addClass('addtocart--added');

            $('body').trigger('cartUpdateInit');

            $.post('/cartapi/add', getVars(), function() {
                reset();

                const $quantity = $('input[name="quantity"]', $this);

                const quantity = $quantity.length ? parseInt($quantity.val()) : 1;

                gtag('event', 'add_to_cart', {
                    value: quantity,
                    currency: 'PLN',
                    items: [
                        {
                            id: $this.data('pid'),
                            name: $this.data('title'),
                            price: $this.data('price'),
                        }
                    ]
                });

                window.setTimeout(function() {
                    $this.removeClass('addtocart--added');

                    adding=false;
                }, 2000);

                $('body').trigger('cartUpdate');
            });
        }

        $button.click(function (e) {
            e.preventDefault();

            submit();
        });
    }
}
