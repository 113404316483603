interface OpenInfoOptions {
	width?: number;
	height?: number;
	css?: string;
}

export default class OpenInfo {
	private $box: JQuery;
	public options: OpenInfoOptions;

	public constructor(options?: OpenInfoOptions) {
		this.options = $.extend({

		}, options);
	}

	private updateLayout(): void {
		let $pobody = $('.pobox__body', this.$box);

		let ww = this.options.width;
		let hh = this.options.height;
		let fx = hh / ww * 100;

		if (hh > $(window).height() * 0.8) {
			ww = ($(window).height() * 0.8) * ww / hh;
		}

		$pobody.css({
			'width': ww + 'px',
			'padding-top': fx + '%'
		});
	}

	private doHtml(): void {
		this.$box = $('<div class="pobox"> \
			<div class="pobox__overlay"> \
			<div class="pobox__inner"> \
				<a class="pobox__close"></a> \
				<div class="pobox__body"></div> \
			</div> \
		</div>');

		if (this.options.css !== null) {
			this.$box.addClass(this.options.css);
		}

		this.$box.addClass('pobox--loading');
		this.$box.hide();
	}

	private load(url: string): void {
		$.get(url, (html) => {
			let $html = $(html);
			let $pobody = $('.pobox__body', this.$box);

			if (this.options.width) {
				$(window).resize(() => {
					this.updateLayout()
				});

				this.updateLayout();
			}

			$pobody.append($html);

			this.$box.removeClass('pobox--loading');
		});
	}

	private open(url: string): void {
		this.doHtml();

		$('body').append(this.$box);

		$(document).click((e) => {
			const $target = $(e.target);

			if ($target.is($('.pobox__overlay, .pobox__close'))) {
				this.close();
			}
		});

		$('body').addClass('body--lock');

		this.$box.fadeIn();

		this.load(url);
	}

	private close(): void {
		this.$box.fadeOut(200, () => {
			$('body').removeClass('body--lock');

			this.$box.remove();
		});
	}

	public static openInfo(url: string, options?: any): OpenInfo {
		let oi = new OpenInfo(options);
		oi.open(url);

		return oi;
	}

	public static initModule(): void {
		(<any>window).OpenInfo = OpenInfo;
	}
}
